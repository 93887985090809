import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';

interface User {
  username: string;
  uid: string;
  email: string;
  dateCreated: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private user: User;
  public printData: any;

  constructor( public afAuth: AngularFireAuth ) { }

  setUser( user: User) {
    this.user = user;
  } // end function setUser()

  isLoggedIn() {
    if ( this.user ) {
      return true;
    } else {
      return false;
    }
  }

  async isAuthenticated() {

    const user = await this.afAuth.authState.pipe(first()).toPromise();

    if (user) {
      this.setUser({
        username: user.displayName,
        uid: user.uid,
        email: user.email,
        dateCreated: user.metadata['a'],
      });
      return true;
    } else {
      return false;
    }

  } // end function isAuthenticated()




  getUID(): string {
    return this.user.uid;
  }  // end function getUID()

  getEmail(): string {
    return this.user.email;
  }


  getUsername(): string {
    return this.user.username;
  }

  getdateCreated(): string {
    return this.user.dateCreated;
  }
  clear() {
    this.user = null;
  }



}
