import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor( public router: Router, public user: UserService ) { }

  async canActivate() {

    if ( await this.user.isAuthenticated() ) {
      console.log('Authentication Successful!');

      return true;
    } else {
      console.log('no isAuthenticated');
      this.router.navigate(['/login']);
      return false;
    }
  }

}
