import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AvatarDialogComponent } from '../avatar-dialog/avatar-dialog.component';
import { FirebaseService } from '../services/firebase.service';
import { Router } from '@angular/router';
import { UserService } from '../user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  exampleForm: FormGroup;
  item: any;
  item_ID: any;




  validation_messages = {
   'name': [
     { type: 'required', message: 'Name is required.' }
   ],
   'state': [
     { type: 'required', message: 'State is required.' }
   ],
   'city': [
     { type: 'required', message: 'City is required.' },
   ]
 };

  constructor(
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public userService: UserService,
  ) { }

  ngOnInit() {
    this.route.data.subscribe(routeData => {
      const data = routeData['data'];
      if (data) {
        this.item = data.payload.data();
        this.item_ID = data.payload.id;
        // this.item = data.payload.id;
        console.log('payload.id: ' + data.payload.id);
        this.createForm();
      }
    })
  }

  createForm() {
    console.log('Name: ' + this.item.name);
    console.log('State: ' + this.item.state);
    console.log('City: ' + this.item.city);

    this.exampleForm = this.fb.group({

      establishment: [this.item.city, Validators.required],
      name: [this.item.name, Validators.required],
      numAdult: [this.item.numAdult, Validators.required],
      numChildren: [this.item.numChildren, Validators.required],
      arrivalDate: [this.item.arrivalDate, Validators.required],
      departureDate: [this.item.departureDate, Validators.required],
      country: [this.item.country, Validators.required],
      nationality: [this.item.nationality, Validators.required],
      state: [this.item.state, Validators.required],
      city: [this.item.city, Validators.required],
      address: [this.item.address, Validators.required],
      email: [this.item.email, Validators.required],
      reservationsnumber: [this.item.reservationsnumber, Validators.required],
      notes: [this.item.notes, ],

    });


    console.log('dates --> ' + this.item.arrivalDate );
    console.log('establishment --> ' + this.item.establishment );



  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.item.avatar = result.link;
      }
    });
  }

  onSubmit(value) {


    console.log('onSubmit function -> ');
    // console.log(this.item_ID);
    // console.log('value');
    // console.log(value );

    // this.firebaseService.updateUser(this.item_ID, value);

    this.firebaseService.updateUser(this.item_ID, value)
    .then(
      res => {
        this.router.navigate(['/home']);
      }
    );

  }

  delete() {
    this.firebaseService.deleteUser(this.item.id)
    .then(
      res => {
        this.router.navigate(['/home']);
      },
      err => {
        console.log(err);
      }
    )
  }

  cancel(){
    this.router.navigate(['/']);
  }

  redirectTo(route: string, data: any): void {
    this.userService.printData = data;
    this.router.navigateByUrl(`/${route}`);
  }


}
