import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(public db: AngularFirestore) {}


  getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();
    const CurDate =  yyyy + '-' + mm + '-' + dd;

    return CurDate;
    // console.log('curDate: ', CurDate);
    // console.log('string: ', '2021-03-05')
    // return '2021-03-05';
  }

  getAvatars() {
      return this.db.collection('/avatar').valueChanges();
  }

  getUser(userKey) {
    return this.db.collection('checkinForm').doc(userKey).snapshotChanges();
  }

  updateUser(userKey, value) {
    console.log('userkey: ' + userKey);
    console.log('value: ');
    console.log(value);

    return this.db.collection('checkinForm').doc(userKey).set(value);
  }

  deleteUser(userKey) {
    return this.db.collection('users').doc(userKey).delete();
  }

  getUsers() {
    return this.db.collection('checkinForm').snapshotChanges();
  }

  getJungleCheckinForms(val) {
    return this.db.collection('checkinForm', ref => ref.where('arrivalDate', '==', val ).where('establishment', '==', 'Sleeping Giant Lodge' )).snapshotChanges();
  }

  getBeachCheckinForms(val) {
    return this.db.collection('checkinForm', ref => ref.where('arrivalDate', '==', val )).snapshotChanges();
  }


  searchUsers(searchValue) {
    return this.db.collection('checkinForm', ref => ref.where('name', '>=', searchValue)
      .where('name', '<=', searchValue + '\uf8ff'))
      .snapshotChanges();
  }

  searchUsersByDate(value) {
    // search by arrival date
    console.log('Arrival Date: ',value)
    // return this.db.collection('checkinForm', ref => ref.orderBy('arrivalDate').startAt(value)).snapshotChanges();
    return this.db.collection('checkinForm', ref => ref.where('arrivalDate', '==', value )).snapshotChanges()

  }


  createUser(value, time) {
    return this.db.doc(`checkinForm/${time}`).set({
      address: value.address,
      arrivalDate: value.arrivalDate,
      city: value.city,
      country: value.country,
      departureDate: value.departureDate,
      email: value.email,
      establishment: value.establishment,
      name: value.name,
      nationality: value.nationality,
      numAdult: value.numAdult,
      numChildren: value.numChildren,
      state: value.state,
      reservationsnumber: value.reservationsnumber,
      notes: value.notes,
    });
  }

  updatedCheckinCounter() {


    // Updateing form counter
    let countValue;
    const count = this.db.doc('checkinCounter/counter').get().subscribe ( async snap => {
      countValue = snap.data().counter;
      countValue = countValue + 1;
      console.log('printing count Value');
      console.log(countValue);

      await this.db.doc('checkinCounter/counter').update({ counter: countValue });

    });


  }


}
