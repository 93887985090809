import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NewUserComponent } from './new-user/new-user.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { LoginComponent } from './login/login.component';
import { EditUserResolver } from './edit-user/edit-user.resolver';
import { AuthService } from './auth.service';
import { PrintpageComponent } from './printpage/printpage.component';
import { CompletedComponent } from './completed/completed.component';

export const rootRouterConfig: Routes = [
  { path: 'login', component: LoginComponent,  },
  { path: 'home', component: HomeComponent, canActivate: [ AuthService ] },
  { path: 'print', component: PrintpageComponent, canActivate: [ AuthService ] },
  { path: 'completed', component: CompletedComponent, canActivate: [ AuthService ] },
  { path: 'new-user', component: NewUserComponent, canActivate: [ AuthService ] },
  { path: 'details/:id', component: EditUserComponent, resolve: {data : EditUserResolver} },
  { path: '', component: HomeComponent, canActivate: [ AuthService ] },

];
