import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';

@Component({
  selector: 'app-printpage',
  templateUrl: './printpage.component.html',
  styleUrls: ['./printpage.component.css']
})
export class PrintpageComponent implements OnInit {

  printData: any;
  checkifset = false;

  constructor(
    public userservice: UserService,
  ) { }

  ngOnInit() {
      document.body.style.backgroundImage = 'none';

    this.printData = this.userservice.printData;
    if (this.printData === undefined) {
      this.printData = 'empty';
    } else {
      this.checkifset = true;
    }

    console.log('printing user data');
    console.log(this.printData);
  }


  printElement(id) {


      const printHtml = document.getElementById(id).outerHTML;
      const currentPage = document.body.innerHTML;
      const elementPage = '<html><head><title></title></head><body>' + printHtml + '</body>';
      document.body.innerHTML = elementPage;
      window.print();
      document.body.innerHTML = currentPage;


    }

}
