import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../user.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  exampleForm: FormGroup;
  isValid: string;



  validation_messages = {
    'email': [
      { type: 'required', message: 'Email is required.' }
    ],
    'password': [
      { type: 'required', message: 'Password is required.' }
    ],
  };


  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    public user: UserService,
    public router: Router,
    public db: AngularFirestore,
    ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.exampleForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['', Validators.required ],
    });
  }

  resetFields() {
    this.exampleForm = this.fb.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  async verifyisAdmin(email) {
    if ( email == null ) {
      this.isValid = 'false';
    } else {

      const adminemails =  this.db.collection('/adminUser', ref => ref.where('email', '==', email)).valueChanges();
      adminemails.subscribe(res => {
        if (res.length === 0 ) {

          alert('Sorry, you are not an admin...');
          this.isValid = 'false';
        } else {

          this.isValid = 'true';
        }
      });
    }

  }


  async onSubmit(value) {
    const email = value.email.trim();
    const pwd = value.password;

    this.verifyisAdmin(email);


    setTimeout( () => {
      if (this.isValid === undefined) {
          console.log('buffering...');
          console.log('verifiyed is admin...');
          console.log('value: ' + this.isValid);

          // loggin in -- @delroycoc
      } else {
        this.singin(email, pwd);
      }


  }, 1000);

  }





  async singin(email, pwd) {

    if ( this.isValid === 'true' ) {
      try {
        const res = await this.afAuth.auth.signInWithEmailAndPassword(email, pwd);
        if ( res.user ) {
          this.user.setUser({
            username: '',
            uid: res.user.uid,
            email: res.user.email,
            dateCreated: res.user.metadata['a'],
          });
          console.log('loggedin');
          this.resetFields();
          this.router.navigate(['/home/'], { replaceUrl: true });
        }
      } catch (err) {
        this.resetFields();
        alert('Error! ' + err.message );
        console.dir(err);
      }

      console.log('submitted!');
      // console.log(value);

    }
  }


}
