import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { AvatarDialogComponent } from "../avatar-dialog/avatar-dialog.component";
import { Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})

export class NewUserComponent implements OnInit {

  exampleForm: FormGroup;
  avatarLink: string = "https://s3.amazonaws.com/uifaces/faces/twitter/adellecharles/128.jpg";

  validation_messages = {
   'name': [
     { type: 'required', message: 'Name is required.' }
   ],
   'surname': [
     { type: 'required', message: 'Surname is required.' }
   ],
   'age': [
     { type: 'required', message: 'Age is required.' },
   ]
 };

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.exampleForm = this.fb.group({
      establishment: ['', Validators.required],
      name: ['', Validators.required],
      numAdult: ['', Validators.required],
      numChildren: ['', Validators.required],
      arrivalDate: ['', Validators.required],
      departureDate: ['', Validators.required],
      country: ['', Validators.required],
      nationality: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', Validators.required],
      reservationsnumber: ['', Validators.required],
      notes: ['', ],
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarDialogComponent, {
      height: '400px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.avatarLink = result.link;
      }
    });
  }

  resetFields() {

    this.exampleForm = this.fb.group({
      establishment: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      numAdult: new FormControl('', Validators.required),
      numChildren: new FormControl('', Validators.required),
      arrivalDate: new FormControl('', Validators.required),
      departureDate: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      nationality: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      notes: new FormControl('', Validators.required),
      reservationsnumber: new FormControl('', Validators.required),

    });
  }

  onSubmit(value) {
    console.log(value);
    const time = new Date().toUTCString();
    console.log('adding new user...');

    this.firebaseService.createUser(value, time)
    .then(
      res => {
        this.resetFields();
        this.firebaseService.updatedCheckinCounter();
        this.router.navigate(['/home']);
      }
    );

  }

}




