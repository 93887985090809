// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyB3XLop2teC3voI64Ap4FQII41cvhnhD6Y",
    authDomain: "the-belize-collection.firebaseapp.com",
    databaseURL: "https://the-belize-collection.firebaseio.com",
    projectId: "the-belize-collection",
    storageBucket: "the-belize-collection.appspot.com",
    messagingSenderId: "6581997748",
    appId: "1:6581997748:web:27630f96e39affad8c47e1",
    measurementId: "G-59V61L0WBX"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
