import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { Router, Params } from '@angular/router';
import { UserService } from '../user.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss']
})

export class CompletedComponent implements OnInit {

  ageValue: number = 0;
  searchValue: string = "";
  items: Array<any>;
  age_filtered_items: Array<any>;
  name_filtered_items: Array<any>;

  hasValues: number;

  constructor(
    public firebaseService: FirebaseService,
    private router: Router,
    public userService: UserService,
    public db: AngularFirestore
  ) { }

  ngOnInit() {
    this.getData();
  }

  checkout(itemID) {
    console.log('checking out...');
    console.log( itemID );
    this.db.doc(`checkinForm/${itemID}`).update({ checkout: true });
    console.log('status updated...');
  }

  clear() {
    // this.router.navigate(['/']);
    window.location.reload();
  }

  redirectTo(route: string, data: any): void {
    this.userService.printData = data;
    this.router.navigateByUrl(`/${route}`);
  }

  getData() {
    let date = this.firebaseService.getCurrentDate()
    this.firebaseService.getBeachCheckinForms(date).subscribe(
      data => {
        this.items = data;
        this.age_filtered_items = data;
        this.name_filtered_items = data;
        this.hasValues = data.length;
        // console.log(data.length)
      }
    )

  }

  viewDetails(item){
    console.log(item.payload.doc.id);
    console.log('printed payload.ID');
    this.router.navigate(['/details/' + item.payload.doc.id]);
  }

  capitalizeFirstLetter(value){
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  searchByName(){
    let value = this.searchValue.toLowerCase();
    this.firebaseService.searchUsers(value)
    .subscribe(result => {
      this.name_filtered_items = result;
      this.items = this.combineLists(result, this.age_filtered_items);
    })
  }


  rangeChange(event){
    console.log(event.target.value);
    this.firebaseService.searchUsersByDate(event.target.value)
    .subscribe(result =>{
      this.items = result;
      this.age_filtered_items = result;
      this.hasValues = result.length;
      console.log('range change values: ',result);
      // this.items = this.combineLists(result, this.name_filtered_items);
    })
  }

  combineLists(a, b){
    let result = [];

    a.filter(x => {
      return b.filter(x2 =>{
        if(x2.payload.doc.id == x.payload.doc.id){
          result.push(x2);
        }
      });
    });
    return result;
  }

}
